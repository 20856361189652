type PlanButtonAction = () => void;

export type PlanFunctionality = {
  name: string;
  active: boolean;
  helperText?: string;
  description?: string | number;
  action?: PlanButtonAction;
};

type PlanModule = {
  name: string;
  functionalities: PlanFunctionality[];
};

export type PlanDetails = {
  name: string;
  color: string;
  lineColor: string;
  paymentLink: string;
  buttonAction: PlanButtonAction;
  functionalities: PlanFunctionality[];
  module?: PlanModule[];
};

const createFunctionalities = (
  items: { name?: string,description?: string; active: boolean; action?: PlanButtonAction , options?: any}[]
): PlanFunctionality[] => {
  return items.map(({ name, description, active, action, options }, index) => ({
    name: name,
    description,
    active,
    action,
    options
  }));
};

export const createPlans = (): PlanDetails[] => {
  return [
    {
      name: 'Enterprise 1',
      color: '#63AB36',
      lineColor: '#63AB36',
      paymentLink: 'https://www.google.com',
      buttonAction: () => false,
      functionalities:[],
      module: [
        {
          name: 'Quantidade de veículos',
          functionalities: createFunctionalities([
            { name: '41 a 99 veículos', description: 'R$ 8,81', active: true },
            { name: '100 a 199 veículos', description: 'R$ 7,57', active: true },
            { name: '200 a 499 veículos', description: 'R$ 4,65', active: true },
            { name: '500 a 999 veículos', description: 'R$ 4,28', active: false },
            { name: '1.000 a 4.999 veículos', description: 'R$ 3,64', active: false }
          ]),
        },
        {
          name: 'Soluções',
          functionalities: createFunctionalities([
            { name: 'Dashboards', active: true },
            { name: 'Relatórios' ,active: true },
            { 
              name: 'Mostrar Informações',
              active: true ,
              options: {
                subsection: true,
                items: createFunctionalities([
                  { name: 'Multas', active: true },
                  { name: 'Notificações', active: true },
                  { name: 'Condutores', active: true },
                  { name: 'Veículos', active: true },
                  { name: 'Cronotacógrafo', active: true },
                  { name: 'IPVA', active: false },
                  { name: 'Situação de CNH', active: false },
                  { name: 'Infrações ANTT (Embarcador)', active: false },
                  { name: 'CRLV', active: false },
                ])
              }
            },
            { name: 'Emissão de Multas com 40% de desconto (Integração com SNE)', active: true },
            { name: 'Gestão de Viagens dos condutores', active: false },
            { name: 'Realizar pagamentos de multas dentro da plataforma', active: false, description: 'Pré Pago com taxa de 3%' },
            { name: 'Indicação de condutor', active: false, description: 'R$ 13,28' }
          ]),
        },
      ],
    },
    {
      name: 'Enterprise 2',
      color: '#457826',
      lineColor: '#457826',
      paymentLink: 'https://www.google.com',
      buttonAction: () => false,
      functionalities:[],
      module: [
        {
          name: 'Quantidade de veículos',
          functionalities: createFunctionalities([
            { name: '41 a 99 veículos', description: 'R$ 13,30', active: true },
            { name: '100 a 199 veículos', description: 'R$ 12,07', active: true },
            { name: '200 a 499 veículos', description: 'R$ 9,15', active: true },
            { name: '500 a 999 veículos', description: 'R$ 8,78', active: false },
            { name: '1.000 a 4.999 veículos', description: 'R$ 6,78', active: false }
          ]),
        },
        {
          name: 'Soluções',
          functionalities: createFunctionalities([
            { name: 'Dashboards', active: true },
            { name: 'Relatórios' ,active: true },
            { 
              name: 'Mostrar Informações',
              active: true ,
              options: {
                subsection: true,
                items: createFunctionalities([
                  { name: 'Multas', active: true },
                  { name: 'Notificações', active: true },
                  { name: 'Condutores', active: true },
                  { name: 'Veículos', active: true },
                  { name: 'Cronotacógrafo', active: true },
                  { name: 'IPVA', active: true },
                  { name: 'Situação de CNH', active: false },
                  { name: 'Infrações ANTT (Embarcador)', active: false },
                  { name: 'CRLV', active: false },
                ])
              }
            },
            { name: 'Emissão de Multas com 40% de desconto (Integração com SNE)', active: true },
            { name: 'Gestão de Viagens dos condutores', active: true },
            { name: 'Realizar pagamentos de multas dentro da plataforma', active: false, description: 'Pós pago, sem taxa' },
            { name: 'Indicação de condutor', active: false, description: 'R$ 0,00' }
          ])
        },
      ],
    },
    {
      name: 'Enterprise 3',
      color: '#284516',
      lineColor: '#284516',
      paymentLink: 'https://www.google.com',
      buttonAction: () => false,
      functionalities:[],
      module: [
        {
          name: 'Quantidade de veículos',
          functionalities: createFunctionalities([
            { name: '41 a 99 veículos', description: 'R$ 15,80', active: true },
            { name: '100 a 199 veículos', description: 'R$ 14,57', active: true },
            { name: '200 a 499 veículos', description: 'R$ 11,65', active: true },
            { name: '500 a 999 veículos', description: 'R$ 11,28', active: false },
            { name: '1.000 a 4.999 veículos', description: 'R$ 9,28', active: false }
          ]),
        },
        {
          name: 'Soluções',
          functionalities: createFunctionalities([
            { name: 'Dashboards', active: true },
            { name: 'Relatórios' ,active: true },
            { 
              name: 'Mostrar Informações',
              active: false ,
              options: {
                subsection: true,
                items: createFunctionalities([
                  { name: 'Multas', active: true },
                  { name: 'Notificações', active: true },
                  { name: 'Condutores', active: true },
                  { name: 'Veículos', active: true },
                  { name: 'Cronotacógrafo', active: true },
                  { name: 'IPVA', active: true },
                  { name: 'Situação de CNH', active: true },
                  { name: 'Infrações ANTT (Embarcador)', active: true },
                  { name: 'CRLV', active: true },
                ])
              }
            },
            { name: 'Emissão de Multas com 40% de desconto (Integração com SNE)', active: true },
            { name: 'Gestão de Viagens dos condutores', active: true },
            { name: 'Realizar pagamentos de multas dentro da plataforma', active: false, description: 'Pós pago, sem taxa' },
            { name: 'Indicação de condutor', active: false, description: 'R$ 0,00' }
          ])
        },
      ],
    }
  ];
};
